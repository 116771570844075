import { createColumnHelper } from '@tanstack/react-table';
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaUserEdit } from 'react-icons/fa';
import { RowObj } from '../index';

const columnHelper = createColumnHelper<RowObj>();

export default function useColumns(): any {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const textColor = useColorModeValue('navy.700', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  return [
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          maxW={130}
        >
          {t('user_table.firstName')}
        </Text>
      ),
      cell: (info: any) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          maxW={130}
          noOfLines={2}
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('lastName', {
      id: 'lastName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user_table.lastName')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user_table.email')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user_table.phone')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('roles', {
      id: 'roles',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user_table.user_type')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {t(`user_roles.${info.getValue()}`)}
        </Text>
      ),
    }),
    columnHelper.accessor('user_actions', {
      id: 'user_actions',
      header: () => {},
      cell: (info) => (
        <Flex gap={5}>
          <Icon
            as={FaUserEdit}
            color={brandColor}
            onClick={() =>
              navigate(
                `/admin/brilliant_users/update_user/${info.row.original.id}`,
              )
            }
            transition="all 0.2s linear"
            cursor="pointer"
            _hover={{ transform: 'scale(1.2)' }}
          />
        </Flex>
      ),
    }),
  ];
}
