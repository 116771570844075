import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IPagination, User } from './@types';
import AuthServices from './auth.api';

class UserService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const _token = localStorage.getItem('token');
      const token = _token ? JSON.parse(_token).tokens.access.token : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Add an interceptor which detect 401 status code
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthServices.logout();
        }

        return Promise.reject(error);
      },
    );
  }

  public async createUser(user: User): Promise<User> {
    const response: AxiosResponse<User> = await this.api.post('/users', user);
    return response.data;
  }

  public async getUsers(
    queryParams?: Record<string, any>,
  ): Promise<IPagination> {
    const response: AxiosResponse<IPagination> = await this.api.get(
      '/users',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getUser(userId: string): Promise<User> {
    const response: AxiosResponse<User> = await this.api.get(
      `/users/${userId}`,
    );
    return response.data;
  }

  public async updateUser(
    userId: string,
    updates: Partial<User>,
  ): Promise<User> {
    const response: AxiosResponse<User> = await this.api.patch(
      `/users/${userId}`,
      updates,
    );
    return response.data;
  }

  public async deleteUser(userId: string): Promise<void> {
    await this.api.delete(`/users/${userId}`);
  }
}

const _userService = new UserService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000',
);

export default _userService;
