// src/features/businesses/userSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  activeUserPerDay,
  NonActiveUsersResults,
  StatsResults,
} from 'services/@types';
import StatsService from 'services/stats.api';
export interface PermissionState {
  stats: StatsResults;
  activeUsers: activeUserPerDay[];
  loading: boolean;
  error: string | null;
  nonActiveUsers: NonActiveUsersResults;
}

const initialState: PermissionState = {
  loading: false,
  error: null,
  stats: {
    totalBusinesses: 0,
    monthlyRegisteredBusinesses: 0,
    dailyActiveUsers: 0,
    monthlyActiveUsers: 0,
    weeklyActiveUsers: 0,
    businessesByCategory: [],
  },
  activeUsers: [],
  nonActiveUsers: {
    total: 0,
    page: 1,
    pageSize: 6,
    results: [],
    totalPages: 0,
    daysAgo: 7,
  },
};

export const getStats = createAsyncThunk(
  'stats/getStats',
  async (_, { rejectWithValue }) => {
    try {
      const stats = await StatsService.getStats();
      return stats;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getActiveUsers = createAsyncThunk(
  'stats/getActiveUsers',
  async (daysAgo: number, { rejectWithValue }) => {
    try {
      const activeUsers = await StatsService.getActiveUsers(daysAgo);
      return activeUsers;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getNonActiveUsers = createAsyncThunk(
  'stats/getNonActiveUsers',
  async (params: { daysAgo: number; page: number; pageSize: number }) => {
    const nonActiveUsers = await StatsService.getNonActiveUsers(params);
    return nonActiveUsers;
  },
);

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setNonActiveParams: (state, action) => {
      state.nonActiveUsers = {
        ...state.nonActiveUsers,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.stats = action.payload;
      })
      .addCase(getStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getActiveUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActiveUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.activeUsers = action.payload;
      })
      .addCase(getActiveUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getNonActiveUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNonActiveUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.nonActiveUsers = { ...state.nonActiveUsers, ...action.payload };
      })
      .addCase(getNonActiveUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setNonActiveParams } = statsSlice.actions;

export default statsSlice.reducer;
