import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthServices from '../../../../services/auth.api';
import { clearCredentials } from '../../../../redux/auth/authSlice';
export const useSignout = () => {
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);
  const signOut = useCallback(async () => {
    setLoading(true);
    try {
      await AuthServices.logout();
    } catch (e) {
      console.error('Error logging out: ', e);
    }
    dispatch(clearCredentials());
    setLoading(false);
  }, [dispatch]);

  return { signOut, loading };
};
