import axios, { AxiosInstance, AxiosProgressEvent, AxiosResponse } from 'axios';
import { Upload } from './@types';
import AuthServices from './auth.api';
interface PaginatedUploads {
  results: Upload[];
  page: number;
  limit: number;
  totalPages: number;
  totalResults: number;
}

class UploadService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const _token = localStorage.getItem('token');
      const token = _token ? JSON.parse(_token).tokens.access.token : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Add an interceptor which detect 401 status code
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthServices.logout();
        }

        return Promise.reject(error);
      },
    );
  }

  public async uploadFile(
    target: string,
    targetId: string,
    access: string,
    file: File,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<Upload> {
    const formData = new FormData();
    formData.append('file', file);

    const response: AxiosResponse<Upload> = await this.api.post(
      `/uploads/${target}/${targetId}/${access}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Allow-Control-Allow-Origin': '*',
        },
        onUploadProgress,
      },
    );
    return response.data;
  }

  public async getUploads(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedUploads> {
    const response: AxiosResponse<PaginatedUploads> = await this.api.get(
      '/uploads',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getUpload(
    path: string,
    size: 'original' | 'sm' | 'md' | 'lg' = 'original',
  ): Promise<Blob> {
    const response: AxiosResponse<Blob> = await this.api.get(
      `/uploads/${path}?size=${size}`,
      { responseType: 'blob' },
    );
    return response.data;
  }

  public async updateUpload(
    target: string,
    targetId: string,
    access: string,
    fileId: string,
    updates: Partial<Upload>,
  ): Promise<Upload> {
    const response: AxiosResponse<Upload> = await this.api.patch(
      `/uploads/${target}/${targetId}/${access}/${fileId}`,
      updates,
    );
    return response.data;
  }

  public async deleteUpload(
    target: string,
    targetId: string,
    access: string,
    fileId: string,
  ): Promise<void> {
    await this.api.delete(`/uploads/${target}/${targetId}/${access}/${fileId}`);
  }
}

const _uploadService = new UploadService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _uploadService;
