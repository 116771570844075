import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closePermissionDialog } from '../../../../redux/dialog/dialogsSlice';
import { RootState } from 'redux/store';
import { Permission } from 'services/@types';
import UserService from 'services/user.api';
import {
  createPermission,
  updatePermission,
} from '../../../../redux/permission/permissionSlice';
import BusinessService from 'services/business.api';
import {
  setUpdatedUserValues,
  updateSelectedUser,
} from '../../../../redux/user/userSlice';

export default function useHandlePermission() {
  const dispatch = useDispatch<any>();
  const toast = useToast();
  const { dialog, user } = useSelector((state: RootState) => ({
    dialog: state.dialogs.permissionDialog,
    user: state.users.user,
  }));
  const { t } = useTranslation();
  const [permission, setPermission] = useState<Permission>(null);
  const [serchValue, setSearchValue] = useState<string>();
  const [serchData, setSearchData] = useState<any>();

  const permissions = useMemo(() => user?.permissions, [user]);

  const inputFields = useMemo(
    () => [
      {
        name: 'name',
        value: permission?.name || '',
      },
      {
        name: 'description',
        value: permission?.description || '',
      },
    ],
    [permission],
  );

  const handleClose = useCallback(() => {
    dispatch(closePermissionDialog());
    setPermission(null);
    setSearchData(null);
    setSearchValue('');
  }, [dispatch]);

  const handleChange = useCallback((e: any) => {
    const { name, value } = e.target;
    setPermission((prev) => ({
      ...prev,
      [name]: name === 'roles' ? [value] : value,
    }));
  }, []);

  const updatePerm = useCallback(async () => {
    try {
      const updatedPerm = await dispatch(
        updatePermission({
          id: dialog?.permission?.id,
          permission: {
            name: permission.name,
            description: permission.description,
          },
        }),
      );
      if (updatedPerm.error) {
        toast({
          title: t('toast.title.rejected'),
          description: updatedPerm.error.message,
          status: 'error',
          duration: 3000,
        });
        return;
      }
      // show toast
      toast({
        title: t('toast.title.success'),
        description: t('toast.description.permission_updated'),
        status: 'success',
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
    }
    handleClose();
  }, [t, toast, dispatch, permission, dialog?.permission?.id, handleClose]);

  const createPerm = useCallback(async () => {
    try {
      const perm = await dispatch(createPermission(permission));
      if (perm.error) {
        toast({
          title: t('toast.title.rejected'),
          description: perm.error.message,
          status: 'error',
          duration: 3000,
        });
        return;
      }
      if (perm.payload) {
        dispatch(
          setUpdatedUserValues({
            permissions: [...permissions, perm.payload.id],
          }),
        );
        await dispatch(updateSelectedUser());
        // show toast
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.permission_added'),
          status: 'success',
          duration: 3000,
        });
      }
      // close dialog
      handleClose();
    } catch (error) {
      console.log(error);
    }
  }, [handleClose, dispatch, permissions, permission, toast, t]);

  const handleSearch = useCallback(async () => {
    try {
      const params: Record<string, any> = {};
      if (permission?.target === 'business') {
        // search business
        if (serchValue) {
          params['businessName'] = serchValue;
        }
        const business = await BusinessService.getBusinesses(params);
        setSearchData(business);
      }
      if (permission?.target === 'user') {
        // search user
        if (serchValue) {
          params['firstName'] = serchValue;
        }
        const user = await UserService.getUsers(params);
        setSearchData(user);
      }
    } catch (error) {
      console.log(error);
    }
  }, [serchValue, permission?.target]);

  useEffect(() => {
    if (dialog?.permission) {
      setPermission(dialog.permission as Permission);
    }
  }, [dialog?.permission, handleSearch]);
  return {
    handleClose,
    createPerm,
    dialog,
    setPermission,
    permission,
    serchData,
    handleSearch,
    setSearchValue,
    setSearchData,
    handleChange,
    inputFields,
    updatePerm,
  };
}
