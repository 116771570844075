import { useEffect, useState } from 'react';
import AuthService from '../../../../services/auth.api';

export default function useAdminExist() {
  const [isAdminExist, setIsAdminExist] = useState<boolean>(true);

  useEffect(() => {
    const getIsAdminExist = async () => {
      try {
        const isAdminExist = await AuthService.isAdminExist();
        setIsAdminExist(isAdminExist.exists);
      } catch (error) {
        console.error(error);
      }
    };
    getIsAdminExist();
  }, []);

  return { isAdminExist };
}
