import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormLabel,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeAlertDialog,
  openPermissionDialog,
} from '../../redux/dialog/dialogsSlice';
import PermissionCard from './permissionCard';
import PermissionService from 'services/permission.api';
import { AxiosError } from 'axios';
import { RootState } from 'redux/store';
import {
  setUpdatedUserValues,
  updateSelectedUser,
} from '../../redux/user/userSlice';

export default function PermissionsCard() {
  const dispatch = useDispatch<any>();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const toast = useToast();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.users.user);

  const permissions = useMemo(() => user?.permissions, [user]);

  const openModal = useCallback(() => {
    dispatch(openPermissionDialog({}));
  }, [dispatch]);

  const handleDelete = useCallback(
    async (id) => {
      try {
        await PermissionService.deletePermission(id);
        dispatch(closeAlertDialog());
        const updatedPermissions = permissions.filter(
          (permission) => permission !== id,
        );
        dispatch(setUpdatedUserValues({ permissions: updatedPermissions }));
        await dispatch(updateSelectedUser());
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.permission_deleted'),
          status: 'success',
          duration: 3000,
        });
      } catch (e) {
        const error = e as AxiosError;
        console.log('error: ', error);
      }
    },
    [dispatch, t, toast, permissions],
  );

  return (
    <Card gap={3}>
      <Flex justifyContent="space-between" alignItems="center">
        <FormLabel fontSize="2xl" fontWeight="700" m="0" color={textColor}>
          {t('create_update_user.permissions')}
        </FormLabel>
        <Button
          colorScheme="grey"
          variant="outline"
          fontSize="sm"
          borderRadius="16px"
          w={{ base: '128px', md: '148px' }}
          h="46px"
          ms="auto"
          onClick={() => openModal()}
        >
          {t('create_update_user.add_permission')}
        </Button>
      </Flex>
      <Flex wrap="wrap" gap={2}>
        {permissions?.map((permissionId: string, index: number) => (
          <PermissionCard
            permissionId={permissionId}
            key={index}
            handleDelete={() => handleDelete(permissionId)}
          />
        ))}
      </Flex>
    </Card>
  );
}
