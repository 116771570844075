// Chakra imports
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormLabel,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdCheck } from 'react-icons/md';
// Custom components

export default function Default(props: {
  id?: string;
  label?: string;
  error?: string;
  extra?: JSX.Element;
  placeholder?: string;
  type?: string;
  [x: string]: any;
}) {
  const {
    id,
    label,
    extra,
    error,
    placeholder,
    options,
    selectedOptions,
    onSelected,
    mb,
    mt,
    menuButton,
  } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { t } = useTranslation();
  return (
    <Flex direction="column" mb={mb ? mb : '30px'} mt={mt}>
      <FormLabel
        display="flex"
        htmlFor={id}
        fontSize="sm"
        color={textColor}
        fontWeight="medium"
        _hover={{ cursor: 'pointer' }}
      >
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          size="sm"
          textAlign="start"
          borderRadius="16px"
          _active={{ borderColor: 'brand.600' }}
          border="1px solid"
          color={textColor}
          borderColor={'gray.300'}
          h="50px"
          maxH="50px"
          {...menuButton}
        >
          {placeholder}
        </MenuButton>
        <MenuList
          minWidth={{ base: '100%', md: '420px' }}
          style={{ maxHeight: 300, overflow: 'scroll' }}
          borderRadius="2xl"
        >
          {options?.map((option: string, key: number) => (
            <MenuItem key={key} onClick={() => onSelected(option)} w="100%">
              <HStack w="100%">
                <Text>{t(option)}</Text>
                <Flex flex={1} />
                <Icon
                  as={MdCheck}
                  color="brand.400"
                  fontSize="xl"
                  display={selectedOptions?.includes(option) ? 'block' : 'none'}
                />
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Text fontSize="xs" color="red.500" mt="2px" fontWeight={'semibold'}>
        {t(error)}
      </Text>
    </Flex>
  );
}
