import { useEffect, useMemo, useState } from 'react';
import BusinessService from 'services/business.api';
import UserService from 'services/user.api';
interface ITarget {
  target: string;
  targetId: string;
}
export default function useTarget(props: ITarget) {
  const { target, targetId } = props;
  const [targetData, setTargetData] = useState<any>();
  const targetName = useMemo(
    () =>
      targetData?.businessName ||
      targetData?.firstName + ' ' + targetData?.lastName,
    [targetData?.businessName, targetData?.firstName, targetData?.lastName],
  );
  const imagePath = useMemo(
    () =>
      targetData?.media?.find(
        (i: any) => i.type === 'profileImage' || i.type === 'avatar',
      )?.url,
    [targetData?.media],
  );
  useEffect(() => {
    const fetchTarget = async () => {
      try {
        if (target === 'business') {
          const business = await BusinessService.getBusiness(targetId);
          setTargetData(business);
        } else if (target === 'user') {
          const user = await UserService.getUser(targetId);
          setTargetData(user);
        }
      } catch (e) {
        console.error(e);
      }
    };
    if (!targetData) {
      fetchTarget();
    }
  }, [targetData, target, targetId]);
  return { targetName, imagePath };
}
