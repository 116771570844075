import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function AlertDialog() {
  const dialog = useSelector((state: RootState) => state.dialogs.alertDialog);
  const { title, content, onClose, onConfirm } = dialog || {};
  const { t, i18n } = useTranslation();
  const redColor = useColorModeValue('red.500', 'red.400');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton
          left={i18n.dir() === 'ltr' ? 'auto' : '0'}
          right={i18n.dir() === 'rtl' ? 'auto' : '0'}
        />
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button color={brandColor} onClick={onClose}>
            {t('dialog.back')}
          </Button>
          <Button color={redColor} onClick={onConfirm}>
            {t('dialog.confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
