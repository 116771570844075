import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import AdminService from '../../../../../../services/admin.api';

function useDeleteUser() {
  const [error, setError] = useState<string>('');

  const deleteUser = useCallback(async (userId: string) => {
    try {
      await AdminService.deleteAdmin(userId);
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
  }, []);

  return {
    deleteUser,
    error,
  };
}

export default useDeleteUser;
