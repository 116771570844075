import { createColumnHelper } from '@tanstack/react-table';
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaUserEdit } from 'react-icons/fa';
import { RowObj } from '../index';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from 'redux/store';

const columnHelper = createColumnHelper<RowObj>();

export default function useColumns(): any {
  const { businessTags } = useSelector((state: RootState) => state.businessTag);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const textColor = useColorModeValue('navy.700', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const getFilteredBusinessTags = useCallback(
    (category) => {
      const filteredBusinessTags: string[] = [];
      category.forEach((id: string) => {
        const businessTag = businessTags.find(
          (businessTag) => businessTag.id === id,
        );
        if (businessTag) {
          filteredBusinessTags.push(t(`business_tags.${businessTag.name}`));
        }
      });
      return filteredBusinessTags.join(', ');
    },
    [businessTags, t],
  );

  return [
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('business_table.email')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('businessName', {
      id: 'businessName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('business_table.name')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('category', {
      id: 'category',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('business_table.category')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {getFilteredBusinessTags(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('business_table.phone')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('businessVATId', {
      id: 'businessVATId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('business_table.business_vat_id')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => {},
      cell: (info) => (
        <Flex gap={5}>
          <Icon
            as={FaUserEdit}
            color={brandColor}
            onClick={() =>
              navigate('/admin/businesses/update_business', {
                state: info.row.original,
              })
            }
            transition="all 0.2s linear"
            cursor="pointer"
            _hover={{ transform: 'scale(1.2)' }}
          />
        </Flex>
      ),
    }),
  ];
}
