import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import BusinessService from '../../../../../services/business.api';

function useDeleteBusiness() {
  const [error, setError] = useState<string>('');

  const deleteBusiness = useCallback(async (businessId: string) => {
    try {
      await BusinessService.deleteBusiness(businessId);
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
  }, []);

  return {
    deleteBusiness,
    error,
  };
}

export default useDeleteBusiness;
