import React from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Custom hooks
import useUpdateBusiness from './hooks/useUpdateBusiness';
import UserBadge from 'components/userBarge';
import { useDispatch } from 'react-redux';
import {
  closeAlertDialog,
  openAlertDialog,
} from '../../../../redux/dialog/dialogsSlice';
import useDeleteBusiness from './hooks/useDeleteBusiness';

const fileds = [
  {
    name: 'businessName',
    label: 'update_business.business_name',
    placeholder: 'update_business.placeholder.business_name',
  },
  {
    name: 'email',
    label: 'update_business.email',
    placeholder: 'update_business.placeholder.email',
  },
  {
    name: 'phone',
    label: 'update_business.phone',
    placeholder: 'update_business.placeholder.phone',
  },
  {
    name: 'businessVATId',
    label: 'update_business.business_vat_id',
    placeholder: 'update_business.placeholder.business_vat_id',
  },
  {
    name: 'address',
    label: 'update_business.address',
    placeholder: 'update_business.placeholder.address',
  },
];

const links = [
  {
    name: 'facebook',
    label: 'Facebook',
    placeholder: 'update_business.placeholder.facebook',
  },
  {
    name: 'instagram',
    label: 'Instagram',
    placeholder: 'update_business.placeholder.instagram',
  },
  {
    name: 'tiktok',
    label: 'Tiktok',
    placeholder: 'update_business.placeholder.tiktok',
  },
  {
    name: 'twitter',
    label: 'Twitter',
    placeholder: 'update_business.placeholder.twitter',
  },
];

function UpdateBusiness() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const {
    inputValues,
    handleChange,
    updateBusiness,
    loading,
    error,
    handleBusinessCategoriesSelected,
    selectedCategory,
    categoryOptions,
    getCurrentLink,
    user,
  } = useUpdateBusiness();

  // custom hook for deleting user
  const { deleteBusiness } = useDeleteBusiness();

  // handle click event for delete button
  const handleClick = (id: string): void => {
    dispatch(
      openAlertDialog({
        content: t('business_table.are_u_sure'),
        title: t('business_table.delete_business'),
        onClose: () => {
          dispatch(closeAlertDialog());
        },
        onConfirm: () => {
          deleteBusiness(id);
          dispatch(closeAlertDialog());
          navigate(-1);
        },
      }),
    );
  };
  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        mt={{ base: '60px', md: '165px' }}
        display="flex"
        flexDirection="column"
      >
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
            gap={8}
          >
            <Flex flexDirection="column" gap={10}>
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  {t('update_business.update_business')}
                </Text>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <SimpleGrid gap="20px">
                      <FormControl>
                        {fileds.map((field, key) => (
                          <React.Fragment key={key}>
                            <FormLabel
                              fontWeight="600"
                              m="10px 10px 0"
                              color={textColor}
                              fontSize="sm"
                            >
                              {t(field.label)}
                            </FormLabel>
                            <InputField
                              mb="0px"
                              placeholder={t(field.placeholder)}
                              name={field.name}
                              value={inputValues[field.name] || ''}
                              onChange={handleChange}
                            />
                          </React.Fragment>
                        ))}
                        <FormLabel
                          fontWeight="600"
                          m="10px 10px 0px"
                          color={textColor}
                          fontSize="sm"
                        >
                          {t('update_business.business_category')}
                        </FormLabel>
                        <DropdownField
                          options={categoryOptions}
                          placeholder={t('update_business.select_categories')}
                          onSelected={handleBusinessCategoriesSelected}
                          name="category"
                          selectedOptions={selectedCategory}
                          menuButton="100%"
                        />
                        {links.map((link, index) => (
                          <React.Fragment key={index}>
                            <FormLabel
                              fontWeight="600"
                              m="10px 10px 0"
                              color={textColor}
                              fontSize="sm"
                            >
                              {t(link.label)}
                            </FormLabel>
                            <InputField
                              mb="0px"
                              id="first"
                              name={link.name}
                              value={getCurrentLink(link.name)}
                              placeholder={t(link.placeholder)}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const currentLinks = inputValues?.links || [];
                                const currentLink = currentLinks?.find(
                                  (el: any) => el.type === link.name,
                                );
                                let value;
                                if (currentLink) {
                                  value = currentLinks.map((link: any) =>
                                    link.type === currentLink.type
                                      ? { ...link, url: e.target.value }
                                      : link,
                                  );
                                } else {
                                  value = [
                                    ...currentLinks,
                                    { type: link.name, url: e.target.value },
                                  ];
                                }
                                handleChange({
                                  target: {
                                    name: 'links',
                                    value: value,
                                  },
                                });
                              }}
                              required={true}
                            />
                          </React.Fragment>
                        ))}
                      </FormControl>
                    </SimpleGrid>
                  </Stack>
                </Flex>
              </Card>
              {user && (
                <Card>
                  <UserBadge user={user} />
                </Card>
              )}
              <Card>
                <Flex
                  h="auto"
                  justifyContent="space-between"
                  gap={5}
                  wrap="wrap"
                >
                  <Flex>
                    <Button
                      variant="outline"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      isLoading={loading}
                      colorScheme="red"
                      onClick={() => handleClick(inputValues.id)}
                    >
                      {t('update_business.delete_business')}
                    </Button>
                  </Flex>
                  <Flex gap={5}>
                    <Button
                      colorScheme="grey"
                      variant="outline"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      onClick={() => navigate(-1)}
                      isLoading={loading}
                    >
                      {t('update_business.cancel')}
                    </Button>
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      onClick={() => updateBusiness()}
                      isLoading={loading}
                    >
                      {t('update_business.update_business')}
                    </Button>
                  </Flex>
                </Flex>
                <Text color="red.500" fontSize="sm" textAlign="center">
                  {error}
                </Text>
              </Card>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default UpdateBusiness;
