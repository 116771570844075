import { useState, useEffect, useMemo, useRef } from 'react';
import Card from 'components/card/Card';
import { useDispatch } from 'react-redux';
import { Business } from 'services/@types';
import { Flex } from '@chakra-ui/react';
import {
  PaginationState,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import BusinessesTable from './businessesTable';
import BusinessService from '../../../../services/business.api';
import BusinessesSearchBar from './businessesSearchBar';
import useColumns from './hooks/useColumns';

const DEFAULT_PAGE_SIZE: number = 6;
const DEFAULT_PAGE_INDEX: number = 1;

export interface RowObj {
  category?: string[];
  id: string;
  businessName: string;
  email: string;
  businessVATId: string;
  phone: string;
}

interface fetchBusinessesParams {
  businessName?: string;
  limit?: number;
  page?: number;
}

const createPages = (count: number) => {
  let arrPageCount = [];

  for (let i = 1; i <= count; i++) {
    arrPageCount.push(i);
  }

  return arrPageCount;
};

function BusinessesOverview() {
  const dispatch = useDispatch<any>();
  const { i18n } = useTranslation();

  // states
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const searchRef = useRef<HTMLInputElement | null>(null);

  const pages = useMemo(() => createPages(totalPages), [totalPages]);

  const dir = useMemo(() => i18n.dir(), [i18n]);

  const handleSearchClick = () => {
    setGlobalFilter(searchRef?.current?.value ?? '');
  };

  const handleChangeRowSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = e;

    setPagination((prev) => ({ ...prev, pageSize: Number(value) }));
  };

  const handleChangePageIndex = (index: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: index }));
  };

  // get columns
  const columns = useColumns();

  const table = useReactTable({
    data: businesses,
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    const fetch = async () => {
      const params: fetchBusinessesParams = {
        limit: pageSize,
        page: pageIndex,
      };

      if (globalFilter) {
        params.businessName = globalFilter;
      }

      try {
        const data = await BusinessService.getBusinesses(params);
        if (data?.results) {
          setBusinesses(data.results);
          setTotalPages(data.totalPages);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [dispatch, pageSize, pageIndex, globalFilter]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card px="0px">
        <Flex
          direction="column"
          w="100%"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <BusinessesSearchBar
            globalFilter={globalFilter}
            searchRef={searchRef}
            handleSearchClick={handleSearchClick}
          />
          <BusinessesTable
            table={table}
            pageSize={pageSize}
            pageIndex={pageIndex}
            pages={pages}
            dir={dir}
            handleChangePageIndex={handleChangePageIndex}
            handleChangeRowSize={handleChangeRowSize}
          />
        </Flex>
      </Card>
    </Flex>
  );
}

export default BusinessesOverview;
