// Chakra imports
import { Avatar, Flex, Text, useColorModeValue, Badge } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { User } from 'services/@types';

interface UserBadgeProps {
  user: User;
  [x: string]: any;
}

export default function UserBadge(props: UserBadgeProps) {
  const { user, ...rest } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const filteredUserRole = useMemo(() => {
    return user?.roles[0] ? t(`user_roles.${user?.roles[0]}`) : '';
  }, [t, user?.roles]);

  const avatar = useMemo(
    () => user?.media?.find((i: any) => i.type === 'profileImage')?.url,
    [user],
  );

  const handleClick = useCallback(() => {
    navigate('/admin/brilliant_users/update_user', {
      state: user,
    });
  }, [navigate, user]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      wrap="wrap"
      {...rest}
    >
      <Avatar h="34px" w="34px" src={avatar} me="14px" />
      <Flex direction="column" align="start" me="auto" wrap="wrap">
        <Text
          color={textColor}
          fontSize="md"
          me="6px"
          fontWeight="700"
          cursor="pointer"
          onClick={handleClick}
        >
          {`${user?.firstName} ${user?.lastName}`}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {user?.email}
        </Text>
      </Flex>
      <Badge ms="auto" colorScheme={'green'} color={'green.500'} fontSize="sm">
        {filteredUserRole}
      </Badge>
    </Flex>
  );
}
