import { Flex, Button, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

interface Props {
  globalFilter: string;
  searchRef: React.RefObject<HTMLInputElement>;
  handleSearchClick: () => void;
}

export default function UsersSearchBar(props: Props) {
  const { globalFilter, searchRef, handleSearchClick } = props;
  const { t } = useTranslation();

  // colors
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  return (
    <Flex
      align={{ sm: 'flex-start', lg: 'flex-start' }}
      justify={{ sm: 'flex-start', lg: 'flex-start' }}
      w="100%"
      px="22px"
      mb="36px"
    >
      <SearchBar
        value={globalFilter ?? ''}
        h="44px"
        w={{ lg: '390px' }}
        borderRadius="16px"
        className="p-2 font-lg shadow border border-block"
        placeholder={t('user_table.search_placeholder')}
        reference={searchRef}
      />

      <Button
        m="0 10px"
        background={brandColor}
        color="white"
        fontWeight="200"
        onClick={handleSearchClick}
      >
        {t('user_table.search')}
      </Button>
    </Flex>
  );
}
