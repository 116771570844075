// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  SimpleGrid,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownField from 'components/fields/DropdownField';
// Custom hooks
import useCreateBusiness from './hooks/useCreateBusiness';

const fileds = [
  {
    name: 'businessName',
    label: 'create_business.business_name',
    placeholder: 'create_business.placeholder.business_name',
  },
  {
    name: 'email',
    label: 'create_business.email',
    placeholder: 'create_business.placeholder.email',
  },
  {
    name: 'phone',
    label: 'create_business.phone',
    placeholder: 'create_business.placeholder.phone',
  },
  {
    name: 'businessVATId',
    label: 'create_business.business_vat_id',
    placeholder: 'create_business.placeholder.business_vat_id',
  },
  {
    name: 'address',
    label: 'create_business.address',
    placeholder: 'create_business.placeholder.address',
  },
];

const links = [
  {
    name: 'facebook',
    label: 'Facebook',
    placeholder: 'create_business.placeholder.facebook',
  },
  {
    name: 'instagram',
    label: 'Instagram',
    placeholder: 'create_business.placeholder.instagram',
  },
  {
    name: 'tiktok',
    label: 'Tiktok',
    placeholder: 'create_business.placeholder.tiktok',
  },
  {
    name: 'twitter',
    label: 'Twitter',
    placeholder: 'create_business.placeholder.twitter',
  },
];

export default function CreateBusiness() {
  const { t } = useTranslation();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const {
    handleChange,
    createBusiness,
    loading,
    error,
    handleBusinessCategoriesSelected,
    categoryOptions,
    selectedCategory,
    inputValues,
    getCurrentLink,
  } = useCreateBusiness();

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        mt={{ base: '60px', md: '165px' }}
        display="flex"
        flexDirection="column"
      >
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                {t('create_business.title')}
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid gap="20px">
                    <FormControl>
                      {fileds.map((filed, key) => (
                        <React.Fragment key={key}>
                          <FormLabel
                            fontWeight="600"
                            m="10px 10px 0"
                            color={textColor}
                            fontSize="sm"
                          >
                            {t(filed.label)}
                          </FormLabel>
                          <InputField
                            mb="0px"
                            placeholder={t(filed.placeholder)}
                            name={filed.name}
                            onChange={handleChange}
                            required={true}
                          />
                        </React.Fragment>
                      ))}
                      <FormLabel
                        fontWeight="600"
                        m="10px 10px 0px"
                        color={textColor}
                        fontSize="sm"
                      >
                        {t('create_business.business_category')}
                      </FormLabel>
                      <DropdownField
                        options={categoryOptions}
                        placeholder={t('create_business.select_categories')}
                        onSelected={handleBusinessCategoriesSelected}
                        name="category"
                        selectedOptions={selectedCategory}
                        menuButton="100%"
                      />
                      {links.map((link, index) => (
                        <React.Fragment key={index}>
                          <FormLabel
                            fontWeight="600"
                            m="10px 10px 0"
                            color={textColor}
                            fontSize="sm"
                          >
                            {t(link.label)}
                          </FormLabel>
                          <InputField
                            mb="0px"
                            id="first"
                            name={link.name}
                            placeholder={t(link.placeholder)}
                            value={getCurrentLink(link.name)}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              const currentLinks = inputValues?.links || [];
                              const currentLink = currentLinks?.find(
                                (el: any) => el.type === link.name,
                              );
                              let value;
                              if (currentLink) {
                                value = currentLinks.map((link: any) =>
                                  link.type === currentLink.type
                                    ? { ...link, url: e.target.value }
                                    : link,
                                );
                              } else {
                                value = [
                                  ...currentLinks,
                                  { type: link.name, url: e.target.value },
                                ];
                              }
                              handleChange({
                                target: {
                                  name: 'links',
                                  value: value,
                                },
                              });
                            }}
                            required={true}
                          />
                        </React.Fragment>
                      ))}
                    </FormControl>
                  </SimpleGrid>
                </Stack>

                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => createBusiness()}
                    isLoading={loading}
                  >
                    {t('create_business.create_business')}
                  </Button>
                </Flex>
                <Text color="red.500" fontSize="sm" textAlign="center">
                  {error}
                </Text>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
