// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserWithTokens } from 'services/@types';

// interface AuthState  UserWithTokens & {
//   user: User | null;
//   tokens: {
//     access: string;
//     refresh: string;
//   } | null;
//   isAuthenticated: boolean;
// }

// do the above properly:
interface AuthState extends UserWithTokens {
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  user: null,
  tokens: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<UserWithTokens>) => {
      console.debug('#Redux -> setCredentials...', action.payload);
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
      state.isAuthenticated = true;
    },
    clearCredentials: (state) => {
      console.debug('#Redux -> clearCredentials...');
      localStorage.removeItem('token');
      state.user = null;
      state.tokens = null;
      state.isAuthenticated = false;
    },
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;
