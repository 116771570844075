// src/features/businessTags/userSlice.ts
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessTag, PaginatedResults } from 'services/@types';
import BusinessTagsService from '../../services/businessTag.api';
export interface BusinessState {
  businessTags: BusinessTag[];
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: BusinessState = {
  businessTags: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchBusinessTags = createAsyncThunk(
  'businessTags/fetchBusinessTags',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await BusinessTagsService.getBusinessTags(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const businessTagSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      // Fetch all businesses
      .addCase(fetchBusinessTags.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBusinessTags.fulfilled,
        (state, action: PayloadAction<PaginatedResults>) => {
          state.businessTags = action.payload.results as BusinessTag[];
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(
        fetchBusinessTags.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export default businessTagSlice.reducer;
