/*eslint-disable*/

import {
  Flex,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { LanguagePicker } from 'components/languagePicker/LanguagePicker';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.400', 'white');
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '50px' }}
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          {t('footer.all_rights_reserved')}
        </Text>
      </Text>
      <List display="flex" alignItems="center" gap={2}>
        <ListItem>
          <LanguagePicker />
        </ListItem>
      </List>
    </Flex>
  );
}
