import { Badge, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'services/@types';
import { TiDeleteOutline } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeAlertDialog,
  openAlertDialog,
  openPermissionDialog,
} from '../../../redux/dialog/dialogsSlice';
import PermissionService from 'services/permission.api';
import { RootState } from 'redux/store';
import useTarget from '../hooks/useTarget';
import AuthImageContainer from 'components/authImageContainer';

interface PermissionProps {
  permissionId: string;
  handleDelete: (id: string) => void;
  [x: string]: any;
}
export default function PermissionCard(props: PermissionProps) {
  const { permissionId, handleDelete, ...rest } = props;
  const { t } = useTranslation();
  const { updatedPermissions } = useSelector(
    (state: RootState) => state.permission,
  );
  const dispatch = useDispatch<any>();
  const [permission, setPermission] = useState<Permission>();

  const handleClick = useCallback(() => {
    dispatch(
      openAlertDialog({
        content: t('dialog.permission.are_u_sure'),
        title: t('dialog.permission.delete_permission'),
        onClose: () => {
          dispatch(closeAlertDialog());
        },
        onConfirm: handleDelete,
      }),
    );
  }, [dispatch, t, handleDelete]);

  const editPermission = useCallback(() => {
    dispatch(openPermissionDialog({ permission: permission }));
  }, [permission, dispatch]);

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const permission = await PermissionService.getPermission(permissionId);
        if (permission) {
          setPermission(permission);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchPermission();
  }, [permissionId, updatedPermissions]);

  // custom hook for fetching target data
  const { targetName, imagePath } = useTarget({
    target: permission?.target,
    targetId: permission?.targetId,
  });

  if (!permission) {
    return null;
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      gap={5}
      {...rest}
    >
      <AuthImageContainer
        h="34px"
        w="34px"
        borderRadius="50%"
        name={targetName}
        path={imagePath}
      />
      <Flex direction="column" align="start" me="auto">
        <HStack>
          <Text
            fontSize="md"
            me="6px"
            fontWeight="700"
            cursor="pointer"
            onClick={editPermission}
            wordBreak="break-all"
            noOfLines={2}
          >
            {targetName}
          </Text>
          <Badge>{t(`dialog.permission.target.${permission.target}`)}</Badge>
        </HStack>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {permission?.name}
        </Text>
      </Flex>
      {permission.roles[0] && (
        <Badge
          ms="auto"
          colorScheme={'green'}
          color={'green.500'}
          fontSize="sm"
        >
          {t(`dialog.permission.roles.${permission.roles[0]}`)}
        </Badge>
      )}
      <Icon
        as={TiDeleteOutline}
        color="red.400"
        onClick={() => handleClick()}
        transition="all 0.2s linear"
        cursor="pointer"
        _hover={{ transform: 'scale(1.2)' }}
      />
    </Flex>
  );
}
