import { Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function StatCard({
  label,
  value,
  bg,
  ...rest
}: {
  label: string;
  value: number;
  bg?: string;
  [x: string]: any;
}) {
  const balanceBg = useColorModeValue('brand.900', '#1B254B');
  return (
    <Card
      flexDirection="column"
      bg={bg ? bg : balanceBg}
      minW="150px"
      maxW="420px"
      {...rest}
    >
      <Text
        color="white"
        fontSize="sm"
        fontWeight="500"
        wordBreak="break-all"
        noOfLines={1}
      >
        {label}
      </Text>
      <Text
        color="white"
        fontSize="34px"
        fontWeight="700"
        lineHeight="100%"
        wordBreak="break-all"
        noOfLines={1}
      >
        {value}
      </Text>
    </Card>
  );
}
