import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUsers } from '../../../../redux/stats/statsSlice';
export type DaysAgo = 7 | 30 | 90 | 180;
export default function useActiveUsers() {
  const [daysAgo, setDaysAgo] = useState<DaysAgo>(7);
  const dispatch = useDispatch<any>();
  const { activeUsers } = useSelector((state: any) => state.stats);

  const handleChange = (newValue: DaysAgo) => {
    setDaysAgo(newValue);
  };

  useEffect(() => {
    const fetchActiveUsers = async () => {
      await dispatch(getActiveUsers(daysAgo));
    };
    fetchActiveUsers();
  }, [daysAgo, dispatch]);
  return { handleChange, daysAgo, activeUsers };
}
