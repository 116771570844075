import { Avatar, AvatarProps, Flex } from '@chakra-ui/react';
import AuthImage, { AuthImageProps } from 'components/authImage/AuthImage';
type IAuthImageContainerProps = AvatarProps & AuthImageProps;
export default function AuthImageContainer(props: IAuthImageContainerProps) {
  const { name, path, ...rest } = props;
  return (
    <Flex>
      {!path && <Avatar name={name} {...rest} />}
      {path && <AuthImage path={path} {...rest} />}
    </Flex>
  );
}
