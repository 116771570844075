import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNonActiveUsers,
  setNonActiveParams,
} from '../../../../../redux/stats/statsSlice';
import { RootState } from 'redux/store';
import { createPages } from 'utils/createPages';

export default function useNonActiveUsers() {
  const { results, totalPages, daysAgo, page, pageSize } = useSelector(
    (state: RootState) => state.stats.nonActiveUsers,
  );
  const dispatch = useDispatch<any>();

  const nonActiveUsersData = useMemo(() => results, [results]);

  const updatedDaysAgo = useMemo(() => daysAgo, [daysAgo]);

  const pages = useMemo(() => createPages(totalPages), [totalPages]);

  const updatedpageSize = useMemo(() => pageSize, [pageSize]);

  const updatedpage = useMemo(() => page, [page]);

  const updatedtotalPages = useMemo(() => totalPages, [totalPages]);

  const handleChangeParams = useCallback(
    (value: number, key: string) => {
      dispatch(
        setNonActiveParams({
          [key]: value,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getNonActiveUsers({
          daysAgo: daysAgo,
          page: page,
          pageSize: pageSize,
        }),
      );
    };
    fetchData();
  }, [daysAgo, dispatch, page, pageSize]);

  return {
    daysAgo: updatedDaysAgo,
    nonActiveUsers: nonActiveUsersData,
    pageSize: updatedpageSize,
    totalPages: updatedtotalPages,
    page: updatedpage,
    pages,
    handleChangeParams,
  };
}
