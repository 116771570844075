import { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BusinessService from '../../../../../services/business.api';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { BusinessTag } from 'services/@types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import { fetchUsers } from '../../../../../redux/user/userSlice';

function useUpdateBusiness() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [inputValues, setInputValues] = useState<Record<string, any>>(state);
  const [user, setUser] = useState<any>(null);
  const { businessTags } = useSelector((state: RootState) => state.businessTag);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateBusiness = useCallback(async () => {
    try {
      setLoading(true);
      const business = await BusinessService.updateBusiness(inputValues?.id, {
        businessName: inputValues?.businessName,
        email: inputValues?.email,
        category: inputValues?.category,
        phone: inputValues?.phone,
        businessVATId: inputValues?.businessVATId,
        address: inputValues?.address,
        links: inputValues?.links?.filter((link: any) => link.url),
      });
      if (business) {
        navigate(-1);
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.business_updated'),
          status: 'success',
          duration: 3000,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [inputValues, navigate, toast, t]);

  const handleBusinessCategoriesSelected = useCallback(
    (selected: string) => {
      const tagNames = selected.split('.').pop();
      const selectedId = businessTags?.find(
        (tag: BusinessTag) => tag.name === tagNames,
      )?.id;
      if (inputValues?.category?.includes(selectedId)) {
        setInputValues({
          ...inputValues,
          category: inputValues?.category?.filter((i: any) => i !== selectedId),
        });
        return;
      }
      const category = inputValues?.category || [];
      setInputValues({
        ...inputValues,
        category: [...category, selectedId],
      });
    },
    [businessTags, inputValues, setInputValues],
  );

  const selectedCategory = useMemo(() => {
    let res: string[] = [];
    businessTags?.forEach((el) => {
      if (inputValues?.category?.includes(el?.id)) {
        res.push(`business_tags.${el?.name}`);
      }
    });
    return res;
  }, [businessTags, inputValues?.category]);

  const categoryOptions = useMemo(
    () => businessTags?.map((tag) => `business_tags.${tag.name}`),
    [businessTags],
  );

  const getCurrentLink = useCallback(
    (name: string) => {
      return (
        inputValues?.links?.find((link: any) => link.type === name)?.url || ''
      );
    },
    [inputValues],
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await dispatch(fetchUsers({ businessID: inputValues?.id }));
        if (res?.payload?.results[0]) {
          setUser(res?.payload?.results[0]);
        }
      } catch (e) {}
    };
    fetchUser();
  }, [dispatch, inputValues?.id]);

  return {
    inputValues,
    handleChange,
    updateBusiness,
    loading,
    error,
    handleBusinessCategoriesSelected,
    selectedCategory,
    categoryOptions,
    getCurrentLink,
    user,
  };
}

export default useUpdateBusiness;
