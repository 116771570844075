// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import AuthImageContainer from 'components/authImageContainer';
import { useMemo } from 'react';
import useFormatDate from 'utils/hooks/useFormatDate';

const BG = 'secondaryGray.100';
export default function SearchItem(props: {
  data: any;
  action: () => any;
  targetId: string;
  [x: string]: any;
}) {
  const { data, action, targetId, ...rest } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const name = useMemo(
    () => data?.businessName || data?.firstName,
    [data?.businessName, data?.firstName],
  );
  const imgPath = useMemo(
    () =>
      data?.media?.find(
        (i: any) => i.type === 'profileImage' || i.type === 'avatar',
      )?.url,
    [data?.media],
  );

  const formattedDate = useFormatDate(data?.createdAt);
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      _hover={{ bg: BG }}
      bg={targetId === data.id ? BG : ''}
      transition="0.3s all linear"
      borderRadius="16px"
      maxW={190}
      p={2}
      gap={4}
      onClick={action}
      {...rest}
    >
      <AuthImageContainer
        name={name}
        path={imgPath}
        h={{ base: '40px', '2xl': '50px' }}
        w={{ base: '40px', '2xl': '50px' }}
        borderRadius="50%"
      />
      <Flex direction="column" align="start" me="auto">
        <Text
          color={textColor}
          fontSize={{ base: 'sm', '2xl': 'md' }}
          me="6px"
          fontWeight="700"
          wordBreak="break-all"
          noOfLines={2}
        >
          {name}
        </Text>
        <Text
          color="secondaryGray.600"
          fontSize={{ base: 'xs', '2xl': 'sm' }}
          fontWeight="500"
        >
          {formattedDate}
        </Text>
      </Flex>
    </Flex>
  );
}
