import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import UserService from '../../../../../../services/user.api';
import { useTranslation } from 'react-i18next';

function useCreateUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [error, setError] = useState('');

  const createUser = useCallback(async () => {
    try {
      setLoading(true);
      const user = await UserService.createUser({
        firstName: inputValues?.firstName,
        lastName: inputValues?.lastName,
        email: inputValues?.email,
        password: inputValues?.password,
        phone: inputValues?.phone,
        displayName: inputValues?.displayName,
        roles: [inputValues.roles],
        businessID: inputValues?.businessID,
        jobTitle: inputValues?.jobTitle,
        aboutMe: inputValues?.aboutMe,
        firebaseUID: '123',
      });
      if (user) {
        navigate('/admin/brilliant_users/overview');
        // add toast after user has been created
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.user_created'),
          status: 'success',
          duration: 3000,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [inputValues, navigate, toast, t]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    console.log(`name: ${name} value: ${value}`);
    setInputValues({ ...inputValues, [name]: value });
  };

  return {
    handleChange,
    createUser,
    loading,
    error,
  };
}

export default useCreateUser;
