// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  SimpleGrid,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
// Custom hooks
import useCreateUser from './hooks/useCreateUser';

// user roles
const USER_ROLES = [
  { value: 'admin', label: 'user_roles.admin' },
  { value: 'viewer', label: 'user_roles.viewer' },
  { value: 'support', label: 'user_roles.support' },
  { value: 'manager', label: 'user_roles.manager' },
  { value: 'accounts', label: 'user_roles.accounts' },
];

export default function CreateInternalUser() {
  const { t } = useTranslation();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { handleChange, createUser, loading, error } = useCreateUser();
  const [isPassShow, setIsPassShow] = useState(false);

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        mt={{ base: '60px', md: '165px' }}
        display="flex"
        flexDirection="column"
      >
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Card p="30px">
              <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                {t('create_update_user.title')}
              </Text>
              <Flex direction="column" w="100%">
                <Stack direction="column" spacing="20px">
                  <SimpleGrid gap="20px">
                    <FormControl>
                      <FormLabel
                        fontWeight="600"
                        m="10px 10px 0"
                        color={textColor}
                        fontSize="sm"
                      >
                        {t('create_update_user.name')}
                      </FormLabel>
                      <InputField
                        mb="0px"
                        id="first"
                        placeholder="eg. Esthera"
                        name="firstName"
                        onChange={handleChange}
                      />
                      <FormLabel
                        fontWeight="600"
                        m="10px 10px 0"
                        color={textColor}
                        fontSize="sm"
                      >
                        {t('create_update_user.familyName')}
                      </FormLabel>
                      <InputField
                        mb="0px"
                        id="last"
                        placeholder="eg. Peterson"
                        name="familyName"
                        onChange={handleChange}
                      />
                      <FormLabel
                        fontWeight="600"
                        m="10px 10px 0"
                        color={textColor}
                        fontSize="sm"
                      >
                        {t('create_update_user.email_address')}
                      </FormLabel>
                      <InputField
                        mb="0px"
                        id="Email"
                        placeholder="eg. hello@simmmple.com"
                        name="email"
                        onChange={handleChange}
                      />
                      <FormLabel
                        fontWeight="600"
                        m="10px 10px 0"
                        color={textColor}
                        fontSize="sm"
                      >
                        {t('create_update_user.phone_number')}
                      </FormLabel>
                      <InputField
                        mb="0px"
                        id="first"
                        placeholder="+1 403 012 0241"
                        name="phone"
                        onChange={handleChange}
                      />
                      <FormLabel
                        fontWeight="600"
                        m="10px"
                        color={textColor}
                        fontSize="sm"
                      >
                        {t('create_update_user.password')}
                      </FormLabel>
                      <InputGroup>
                        <Input
                          id="Password"
                          fontSize="sm"
                          ms={{ base: '0px', md: '4px' }}
                          placeholder={t('create_update_user.password')}
                          size="lg"
                          type={isPassShow ? 'text' : 'password'}
                          variant="auth"
                          onChange={handleChange}
                          name="password"
                        />
                        <InputRightElement
                          display="flex"
                          alignItems="center"
                          mt="4px"
                        >
                          <Icon
                            color="gray.400"
                            _hover={{ cursor: 'pointer' }}
                            as={
                              isPassShow
                                ? RiEyeCloseLine
                                : MdOutlineRemoveRedEye
                            }
                            onClick={() => setIsPassShow(!isPassShow)}
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormLabel
                        fontWeight="600"
                        m="10px"
                        color={textColor}
                        fontSize="sm"
                      >
                        {t('create_update_user.choose_user_role')}
                      </FormLabel>
                      <Select
                        fontSize="sm"
                        id="color"
                        variant="main"
                        h="44px"
                        maxH="44px"
                        fontWeight="400"
                        me="20px"
                        defaultValue="dark_grey"
                        onChange={handleChange}
                        name="roles"
                      >
                        {USER_ROLES.map((role, key) => (
                          <option key={key} value={role.value}>
                            {t(role.label)}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </SimpleGrid>
                </Stack>

                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => createUser()}
                    isLoading={loading}
                  >
                    {t('create_update_user.create_user')}
                  </Button>
                </Flex>
                <Text color="red.500" fontSize="sm" textAlign="center">
                  {error}
                </Text>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
