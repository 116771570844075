import { HStack, Select } from '@chakra-ui/react';
import i18n from 'config/i18n/config';
import React, { useCallback, useEffect, useState } from 'react';
import { BsTranslate } from 'react-icons/bs';

export interface SelectedLanguage {
  label: string;
  value: string;
}

const AvailableLanguages: SelectedLanguage[] = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'עברית',
    value: 'he',
  },
];

export const LanguagePicker: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');

  useEffect(() => {
    const language = localStorage.getItem('language');
    setSelectedLanguage(language || 'en');
    i18n.changeLanguage(language || 'en');
  }, []);
  const handleLanguageChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedLanguage(event.target.value);
      i18n.changeLanguage(event.target.value);
      localStorage.setItem('language', event.target.value);
    },
    [],
  );

  return (
    <HStack align="center">
      <Select
        fontSize={'sm'}
        fontWeight={'semibold'}
        textAlign={'center'}
        maxW={28}
        marginStart={4}
        icon={<BsTranslate fontSize={20} />}
        placeholder="Select language"
        value={selectedLanguage}
        onChange={handleLanguageChange}
        cursor={'pointer'}
      >
        {AvailableLanguages.map((lang) => (
          <option key={lang.value} value={lang.value}>
            {lang.label}
          </option>
        ))}
      </Select>
    </HStack>
  );
};
