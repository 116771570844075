import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { IPagination, Admin } from './@types';
import AuthServices from './auth.api';

class AdminService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const _token = localStorage.getItem('token');
      const token = _token ? JSON.parse(_token).tokens.access.token : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Add an interceptor which detect 401 status code
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthServices.logout();
        }

        return Promise.reject(error);
      },
    );
  }

  public async createAdmin(admin: Admin): Promise<Admin> {
    const response: AxiosResponse<Admin> = await this.api.post('/admins', admin);
    return response.data;
  }

  public async getAdmins(
    queryParams?: Record<string, any>,
  ): Promise<IPagination> {
    const response: AxiosResponse<IPagination> = await this.api.get(
      '/admins',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getAdmin(adminId: string): Promise<Admin> {
    const response: AxiosResponse<Admin> = await this.api.get(
      `/admins/${adminId}`,
    );
    return response.data;
  }

  public async updateAdmin(
    adminId: string,
    updates: Partial<Admin>,
  ): Promise<Admin> {
    const response: AxiosResponse<Admin> = await this.api.patch(
      `/admins/${adminId}`,
      updates,
    );
    return response.data;
  }

  public async deleteAdmin(adminId: string): Promise<void> {
    await this.api.delete(`/admins/${adminId}`);
  }
}

const _adminService = new AdminService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000',
);

export default _adminService;
