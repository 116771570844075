import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import AuthService from '../../../../services/auth.api';
import { validateEmail } from 'utils/validation';
export const useSignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const signIn = useCallback(async () => {
    // Your logic here
    if (!validateEmail(email)) {
      setError('Invalid email address');
      return;
    }

    try {
      setLoading(true);
      await AuthService.login({
        email,
        password,
      });
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [email, password]);

  return {
    email,
    setEmail,
    signIn,
    password,
    setPassword,
    loading,
    setLoading,
    error,
    setError,
    show,
    setShow,
  };
};
