import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Business, IBusinessPagination } from './@types';
import AuthServices from './auth.api';
class BusinessService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const _token = localStorage.getItem('token');
      const token = _token ? JSON.parse(_token).tokens.access.token : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Add an interceptor which detect 401 status code
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthServices.logout();
        }

        return Promise.reject(error);
      },
    );
  }

  public async getBusinesses(
    queryParams?: Record<string, any>,
  ): Promise<IBusinessPagination> {
    const response: AxiosResponse<IBusinessPagination> = await this.api.get(
      '/businesses',
      {
        params: queryParams,
      },
    );
    return response.data;
  }
  public async getBusiness(
    id?: string,
  ): Promise<Business> {
    const response: AxiosResponse<Business> = await this.api.get(`/businesses/${id}`);
    return response.data;
  }
  public async deleteBusiness(id: string): Promise<void> {
    await this.api.delete(`/businesses/${id}`);
  }
  public async createBusiness(business: Business): Promise<Business> {
    const response: AxiosResponse<Business> = await this.api.post(
      '/businesses',
      business,
    );
    return response.data;
  }
  public async updateBusiness(
    id: string,
    business: Business,
  ): Promise<Business> {
    const response: AxiosResponse<Business> = await this.api.patch(
      `/businesses/${id}`,
      business,
    );
    return response.data;
  }
}

const _userService = new BusinessService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000',
);

export default _userService;
