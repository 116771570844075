// src/components/ProtectedRoute.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { fetchBusinessTags } from '../redux/businessTag/businessTagSlice';
import { RootState } from '../redux/store';

const ProtectedRoute: React.FC = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.tokens);
  const dispatch = useDispatch<any>();
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchBusinessTags({}));
    }
  }, [isAuthenticated, dispatch]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/sign-in" />;
};

export default ProtectedRoute;
