import {
  Flex,
  FlexProps,
  Image,
  ImageProps,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import _uploadService from '../../services/upload.api';

export interface AuthImageProps extends FlexProps {
  path: string;
  size?: 'original' | 'sm' | 'md' | 'lg';
  imageProps?: ImageProps;
}

const AuthImage: React.FC<AuthImageProps> = (props) => {
  const [imageData, setImageData] = useState<string>('');
  const { path, size, imageProps, ...rest } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (!path) {
          setLoading(false);
          return console.warn('No path provided, auth image skipped.');
        }
        const response = await _uploadService.getUpload(path, size);
        const imageData = URL.createObjectURL(response);
        setImageData(imageData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching image:', error);
        setLoading(false);
        const axiosError: AxiosError = error as AxiosError;
        setError(axiosError.message + '🤔');
      }
    };

    fetchImage();
  }, [path, size]);

  return (
    <Flex
      position="relative"
      {...rest}
      bg="gray.200"
      justify={'center'}
      overflow="hidden"
    >
      {loading && <Skeleton height="100%" width="100%" position="absolute" />}
      <motion.div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: loading ? 0 : 1 }}
        transition={{ duration: 0.5 }}
      >
        {imageData && (
          <Image
            w={'100%'}
            h="100%"
            src={imageData}
            {...imageProps}
            objectFit={rest?.objectFit || 'cover'}
            objectPosition={rest?.objectPosition || 'center'}
          />
        )}
      </motion.div>
      <Flex
        position="absolute"
        top="0"
        left="0"
        pointerEvents={'none'}
        right="0"
        bottom="0"
        borderRadius={'2xl'}
        bg="rgba(255, 255, 255, 0.5)"
        zIndex={1}
        align="center"
        justify="center"
        px={4}
        display={error ? 'flex' : 'none'}
      >
        {error && (
          <Text color="red.400" fontWeight={'semibold'} fontSize={'xs'}>
            {error}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default AuthImage;
