import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeBusinessDialog } from '../../../redux/dialog/dialogsSlice';
import InputField from 'components/fields/InputField';
import { RootState } from 'redux/store';
import { fetchBusinesses } from '../../../redux/business/businessSlice';
import { Business } from 'services/@types';
import BusinessBadge from 'components/businessBadge';
import {
  setUpdatedUserValues,
  updateSelectedUser,
} from '../../../redux/user/userSlice';

export default function BusinessDialog() {
  const { dialog, businesses, loading } = useSelector((state: RootState) => ({
    dialog: state.dialogs.businessDialog,
    businesses: state.business.businesses,
    loading: state.business.loading,
  }));
  const { onClose, onConfirm } = dialog || {};
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const [businessName, setBusinessName] = useState<string>('');
  const toast = useToast();

  const handleClose = useCallback(() => {
    dispatch(closeBusinessDialog());
    if (onClose) {
      onClose();
    }
  }, [onClose, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchBusinesses({}));
    };
    if (dialog) {
      fetch();
    }
  }, [dispatch, dialog]);

  const handleSearchBusiness = useCallback(async () => {
    const params: { businessName?: string } = {};
    if (businessName) {
      params['businessName'] = businessName;
    }
    await dispatch(fetchBusinesses(params));
  }, [dispatch, businessName]);

  const handleSelectBusiness = useCallback(
    async (business: Business) => {
      dispatch(
        setUpdatedUserValues({
          businessID: business.id,
        }),
      );
      await dispatch(updateSelectedUser());
      dispatch(closeBusinessDialog());
      toast({
        title: t('toast.title.success'),
        description: t('toast.description.business_added'),
        status: 'success',
        duration: 3000,
      });
      if (onConfirm) {
        onConfirm();
      }
    },
    [dispatch, t, toast, onConfirm],
  );

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('dialog.business.title')}</ModalHeader>
        <ModalCloseButton
          left={i18n.dir() === 'ltr' ? 'auto' : '0'}
          right={i18n.dir() === 'rtl' ? 'auto' : '0'}
        />
        <ModalBody width="100%">
          <Flex width="100%" flexDirection="column">
            <Flex width="100%" wrap="wrap" alignItems="end" gap={5}>
              <InputField
                placeholder={t('dialog.business.business_placeholder')}
                mb="0"
                value={businessName}
                onChange={(e: any) => setBusinessName(e.target.value)}
                flex="1"
                h="44px"
              />
              <Button
                h="44px"
                variant="darkBrand"
                isLoading={loading}
                onClick={handleSearchBusiness}
              >
                {t('dialog.business.search_business')}
              </Button>
            </Flex>
          </Flex>
          <Flex flex="1" flexDirection="column" mt={5} gap={3}>
            <Text textAlign="center" fontWeight="bold">
              {t('dialog.business.business_list')}
            </Text>
            <Flex
              maxH={30}
              w="100%"
              minH={40}
              overflowY="auto"
              flexDirection="column"
              gap={8}
            >
              {businesses?.length === 0 && (
                <Text textAlign="center" color={brandColor}>
                  {t('dialog.business.no_business')}
                </Text>
              )}
              {businesses?.map((business: Business, key) => (
                <BusinessBadge
                  business={business}
                  key={key}
                  onClick={() => handleSelectBusiness(business)}
                  transition="0.2s linear all"
                  _hover={{ opacity: 0.4 }}
                  cursor="pointer"
                />
              ))}
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="grey"
            variant="outline"
            fontSize="sm"
            borderRadius="16px"
            h="46px"
            onClick={handleClose}
            isLoading={loading}
          >
            {t('dialog.back')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
