import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminService from '../../../../../../services/admin.api';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

function useUpdateUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [inputValues, setInputValues] = useState<Record<string, string>>(state);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setInputValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateUser = useCallback(async () => {
    try {
      setLoading(true);
      const newRole =
        typeof inputValues?.roles === 'string'
          ? [inputValues?.roles]
          : inputValues?.roles;
      const user = await AdminService.updateAdmin(inputValues?.id, {
        name: inputValues?.firstName,
        familyName: inputValues?.familyName,
        email: inputValues?.email,
        phone: inputValues?.phone,
        roles: newRole,
      });
      if (user) {
        navigate('/admin/internal_users/overview');
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.user_updated'),
          status: 'success',
          duration: 3000,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [inputValues, navigate, toast, t]);

  return { inputValues, handleChange, updateUser, loading, error };
}

export default useUpdateUser;
