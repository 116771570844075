import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { PaginatedResults, Permission } from './@types';
import AuthServices from './auth.api';
class PermissionService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const _token = localStorage.getItem('token');
      const token = _token ? JSON.parse(_token).tokens.access.token : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Add an interceptor which detect 401 status code
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthServices.logout();
        }

        return Promise.reject(error);
      },
    );
  }

  public async getPermissions(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults> {
    const response: AxiosResponse<PaginatedResults> = await this.api.get(
      '/permissions',
      {
        params: queryParams,
      },
    );
    return response.data;
  }

  public async getPermission(
    id?: string,
  ): Promise<Permission> {
    const response: AxiosResponse<Permission> = await this.api.get(
      `/permissions/${id}`,       
    );
    return response.data;
  }

  public async deletePermission(id: string): Promise<void> {
    await this.api.delete(`/permissions/${id}`);
  }

  public async createPermission(permission: Permission): Promise<Permission> {
    const response: AxiosResponse<Permission> = await this.api.post(
      '/permissions',
      permission,
    );
    return response.data;
  }

  public async updatePermission(id: string,permission: Partial<Permission>): Promise<Permission> {
    const response: AxiosResponse<Permission> = await this.api.patch(
      `/permissions/${id}`,
      permission,
    );
    return response.data;
  }
}

const _permissionService = new PermissionService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _permissionService;
