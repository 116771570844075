import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { businessByCategory } from 'services/@types';
import { getStats } from '../../../../redux/stats/statsSlice';
import { RootState } from '../../../../redux/store';

export default function useStats() {
  const dispatch = useDispatch<any>();
  const { loading, error, stats, businessTags } = useSelector(
    (state: RootState) => ({
      loading: state.stats.loading,
      error: state.stats.error,
      stats: state.stats.stats,
      businessTags: state.businessTag.businessTags,
    }),
  );

  const businessesByCategory = useMemo(() => {
    const businessesByCategory = stats.businessesByCategory;
    const businesses: businessByCategory[] = [];

    businessesByCategory.forEach((business: any) => {
      const tag = businessTags.find((tag: any) => tag.id === business._id);
      if (tag) {
        businesses.push({ ...business, name: tag.name });
      }
    });

    return businesses;
  }, [stats.businessesByCategory, businessTags]);

  useEffect(() => {
    const fetchStats = async () => {
      await dispatch(getStats());
    };

    fetchStats();
  }, [dispatch]);
  return { loading, error, stats, businessesByCategory };
}
