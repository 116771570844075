import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import SearchItem from './searchItem';
import useHandlePermission from './hooks/useHandlePermission';
interface Target {
  value: string;
  label: string;
}
const TARGETS: Target[] = [
  {
    label: 'dialog.permission.target.empty',
    value: '',
  },
  {
    label: 'dialog.permission.target.business',
    value: 'business',
  },
  {
    label: 'dialog.permission.target.user',
    value: 'user',
  },
];
const ROLES: Target[] = [
  {
    value: 'admin',
    label: 'dialog.permission.roles.admin',
  },
  {
    value: 'viewer',
    label: 'dialog.permission.roles.viewer',
  },
  {
    value: 'editor',
    label: 'dialog.permission.roles.editor',
  },
  {
    value: 'member',
    label: 'dialog.permission.roles.member',
  },
];
const MIN_W: string = '45%';
export default function PermissionDialog() {
  const confirmColor = useColorModeValue('green.500', 'green.400');
  const { t, i18n } = useTranslation();
  const {
    handleClose,
    createPerm,
    dialog,
    setPermission,
    permission,
    serchData,
    handleSearch,
    setSearchValue,
    setSearchData,
    handleChange,
    inputFields,
    updatePerm,
  } = useHandlePermission();

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('dialog.permission.title')}</ModalHeader>
        <ModalCloseButton
          left={i18n.dir() === 'ltr' ? 'auto' : '0'}
          right={i18n.dir() === 'rtl' ? 'auto' : '0'}
        />
        <ModalBody width="100%">
          <FormControl>
            <Flex gap={2} wrap="wrap" justifyContent="center">
              {inputFields.map((field, index) => (
                <Flex flex="1" minW={MIN_W} flexDirection="column" key={index}>
                  <FormLabel>{t(`dialog.permission.${field.name}`)}</FormLabel>
                  <InputField
                    defaultValue=""
                    name={field.name}
                    mb="0px"
                    value={field.value}
                    onChange={handleChange}
                  />
                </Flex>
              ))}
              {!dialog?.permission && (
                <>
                  <Flex flex="1" minW={MIN_W} flexDirection="column">
                    <FormLabel>{t('dialog.permission.select_role')}</FormLabel>
                    <Select
                      fontSize="sm"
                      id="color"
                      variant="main"
                      h="44px"
                      maxH="44px"
                      fontWeight="400"
                      me="20px"
                      mt="10px"
                      name="roles"
                      onChange={handleChange}
                    >
                      {ROLES.map((role, key) => (
                        <option key={key} value={role.value}>
                          {t(role.label)}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  <Flex flex="1" minW={MIN_W} flexDirection="column">
                    <FormLabel>
                      {t('dialog.permission.select_target')}
                    </FormLabel>
                    <Select
                      fontSize="sm"
                      id="color"
                      variant="main"
                      h="44px"
                      maxH="44px"
                      fontWeight="400"
                      me="20px"
                      mt="10px"
                      name="target"
                      onChange={(e: any) => {
                        handleChange(e);
                        setSearchData(null);
                      }}
                    >
                      {TARGETS.map((target, key) => (
                        <option key={key} value={target.value}>
                          {t(target.label)}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                  {permission?.target && (
                    <Flex flexDirection="column" gap={5} minW="100%">
                      <Flex flexDirection="column">
                        <FormLabel>
                          {t(`dialog.permission.select_${permission.target}`)}
                        </FormLabel>
                        <Flex width="100%" wrap="wrap" alignItems="end" gap={5}>
                          <InputField
                            onChange={(e: any) =>
                              setSearchValue(e.target.value)
                            }
                            mb="0"
                            flex="1"
                            h="44px"
                          />
                          <Button
                            h="44px"
                            variant="darkBrand"
                            onClick={handleSearch}
                          >
                            {t('dialog.permission.search')}
                          </Button>
                        </Flex>
                      </Flex>
                      <Flex wrap="wrap" gap={5} maxH="200px" overflowY="auto">
                        {serchData?.results.map((data: any, index: number) => (
                          <SearchItem
                            key={index}
                            data={data}
                            targetId={permission?.targetId}
                            action={() => {
                              setPermission((prev) => ({
                                ...prev,
                                targetId: data.id,
                              }));
                            }}
                          />
                        ))}
                      </Flex>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            colorScheme="grey"
            variant="outline"
            fontSize="sm"
            borderRadius="16px"
            onClick={handleClose}
          >
            {t('dialog.back')}
          </Button>
          <Button
            color={confirmColor}
            colorScheme="green"
            onClick={() => (!dialog?.permission ? createPerm() : updatePerm())}
            variant="outline"
          >
            {t('dialog.confirm')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
