// src/features/businesses/userSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Business } from 'services/@types';
import BusinessService from 'services/business.api';
import store from '../store';
export interface BusinessState {
  businesses: Business[];
  business: Business | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: BusinessState = {
  businesses: [],
  business: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchBusinesses = createAsyncThunk(
  'businesses/fetchBusinesss',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await BusinessService.getBusinesses(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createBusiness = createAsyncThunk(
  'business/createBusiness',
  async (business: Business, { rejectWithValue }) => {
    try {
   
      const {
        auth: {
          user: { email },
        },
      } = store.getState();
      const newBusiness = await BusinessService.createBusiness({
        ...business,
        email,
      });
      console.log('#Redux create business ->', newBusiness);

      return newBusiness;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateBusiness = createAsyncThunk(
  'bussiness/updateBusiness',
  async (
    { businessId, updates }: { businessId: string; updates: Business },
    { rejectWithValue },
  ) => {
    try {
      const response = await BusinessService.updateBusiness(
        businessId,
        updates,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteBusiness = createAsyncThunk(
  'business/deleteBusiness',
  async (businessId: string, { rejectWithValue }) => {
    try {
      await BusinessService.deleteBusiness(businessId);
      return businessId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getBusiness = createAsyncThunk('users/getUserBusiness',async (
  id: string,
  { rejectWithValue },
) => {
  try {
    const response = await BusinessService.getBusiness(id);
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
})

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
    // Any synchronous actions can be defined here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinesses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.businesses = action.payload.results;
        state.pagination = {
          page: action.payload.page,
          limit: action.payload.limit,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalResults,
        };
      })
      .addCase(fetchBusinesses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.business = action.payload;
      })
      .addCase(createBusiness.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.business = action.payload;
      })
      .addCase(updateBusiness.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.businesses = state.businesses.filter(
          (business) => business.id !== action.payload,
        );
      })
      .addCase(deleteBusiness.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      }).addCase(getBusiness.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBusiness.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.business = action.payload;
      })
      .addCase(getBusiness.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userSlice.reducer;
