import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { getBusiness } from '../../../../../../redux/business/businessSlice';
import {
  fetchUser,
  setUpdatedUserValues,
  updateSelectedUser,
} from '../../../../../../redux/user/userSlice';

function useUpdateUser() {
  const { business, user, updatedUserValues } = useSelector(
    (state: RootState) => ({
      selectedBusiness: state.users.selectedBusiness,
      business: state.business.business,
      user: state.users.user,
      updatedUserValues: state.users.updatedUserValues,
    }),
  );
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fields = useMemo(
    () => [
      {
        name: 'email',
        value: user?.email || '',
      },
      {
        name: 'firstName',
        value: user?.firstName || '',
      },
      {
        name: 'lastName',
        value: user?.lastName || '',
      },
      {
        name: 'phone',
        value: user?.phone || '',
      },
      {
        name: 'displayName',
        value: user?.displayName || '',
      },
      {
        name: 'aboutMe',
        value: user?.aboutMe || '',
      },
      {
        name: 'jobTitle',
        value: user?.jobTitle || '',
      },
    ],
    [user],
  );

  const currentBusiness = useMemo(() => business, [business]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = event.target;
      dispatch(
        setUpdatedUserValues({
          ...updatedUserValues,
          [name]: 'roles' === name ? [value] : value,
        }),
      );
    },
    [dispatch, updatedUserValues],
  );

  const updateUser = useCallback(async () => {
    try {
      setLoading(true);
      const updatedUser = await dispatch(updateSelectedUser());
      if (!updatedUser?.error) {
        navigate(-1);
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.user_updated'),
          status: 'success',
          duration: 3000,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [navigate, toast, t, dispatch]);

  useEffect(() => {
    if (user?.businessID) {
      dispatch(getBusiness(user.businessID));
    }
  }, [user?.businessID, dispatch]);

  useEffect(() => {
    const getUser = async () => {
      if (id) {
        await dispatch(fetchUser(id));
      }
    };

    getUser();
  }, [id, dispatch]);

  return {
    handleChange,
    updateUser,
    loading,
    error,
    currentBusiness,
    user,
    fields,
  };
}

export default useUpdateUser;
