import { Flex, Select, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import LineChart from 'components/charts/LineChart';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { activeUserPerDay } from 'services/@types';
import useActiveUsers, { DaysAgo } from '../hooks/useActiveUsers';
import { lineChartOptions } from './lineChartConfig';

const daysAgoTypes: number[] = [7, 30, 90, 180];

export default function ActiveUsersCard() {
  const { t } = useTranslation();
  const { daysAgo, handleChange, activeUsers } = useActiveUsers();

  const chartData = useMemo(
    () => [
      {
        name: t('stats.active_users'),
        data: activeUsers.map((item: activeUserPerDay) => item.activeUsers),
      },
    ],
    [activeUsers, t],
  );

  const chartOptions = useMemo(
    () => ({
      ...lineChartOptions,
      xaxis: {
        ...lineChartOptions.xaxis,
        categories: activeUsers.map((item: activeUserPerDay) =>
          format(new Date(item.timestamp), 'dd/MM/yyyy'),
        ),
      },
    }),
    [activeUsers],
  );
  return (
    <Card gap={2} flex="1" maxW="410px" minW={{ base: '100%', lg: 'xl' }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {t('stats.active_users')}
        </Text>
        <Select
          fontSize="sm"
          variant="subtle"
          defaultValue={daysAgo}
          width="unset"
          ps={5}
          fontWeight="700"
          onChange={(e) => handleChange(Number(e.target.value) as DaysAgo)}
        >
          {daysAgoTypes.map((daysAgo: number, index: number) => (
            <option key={index} value={daysAgo} style={{ textAlign: 'center' }}>
              {daysAgo}
            </option>
          ))}
        </Select>
      </Flex>
      <LineChart
        chartData={chartData}
        chartOptions={chartOptions}
        height="300px"
      />
    </Card>
  );
}
