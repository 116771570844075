import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import routes from 'routes';

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '../../redux/auth/authSlice';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';

// todo: create hook instead useEffects
const DEFAULT_ROUTE = '/admin/stats/overview';

// Custom Chakra theme
export default function Auth() {
  // states and functions
  const dispatch = useDispatch<any>();
  const { i18n } = useTranslation();
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(DEFAULT_ROUTE, { replace: true });
    }
  }, [navigate, user]);
  useEffect(() => {
    const _token = localStorage.getItem('token');

    if (_token) {
      const token = JSON.parse(_token);
      dispatch(setCredentials(token));
    }
  }, [dispatch]);
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route, key) => {
      if (route.layout === '/auth') {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      }
      return null;
    });
  };
  const authBg = useColorModeValue('background.100', 'background.900');
  document.documentElement.dir = 'ltr';
  return (
    <Box sx={{ direction: i18n.dir() }}>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box mx="auto" minH="100vh">
            <Routes>{getRoutes(routes)}</Routes>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
