import { AxiosError } from 'axios';
import { useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import BusinessService from '../../../../../services/business.api';
import { BusinessTag } from 'services/@types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { t } from 'i18next';

function useCreateBusiness() {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState<Record<string, any>>({});
  const [error, setError] = useState('');
  const { businessTags } = useSelector((state: RootState) => state.businessTag);

  const createBusiness = useCallback(async () => {
    try {
      setLoading(true);
      const business = await BusinessService.createBusiness({
        businessName: inputValues?.businessName,
        email: inputValues?.email,
        category: inputValues?.category,
        phone: inputValues?.phone,
        businessVATId: inputValues?.businessVATId,
        address: inputValues?.address,
        links: inputValues?.links?.filter((link: any) => link.url),
      });
      if (business) {
        navigate('/admin/businesses/overview');
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.business_created'),
          status: 'success',
          duration: 3000,
        });
      }
    } catch (e) {
      const error = e as AxiosError;
      setError(error.message);
    }
    setLoading(false);
  }, [inputValues, navigate, toast]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    console.log(`name: ${name} value: ${value}`);
    setInputValues({ ...inputValues, [name]: value });
  };

  const handleBusinessCategoriesSelected = useCallback(
    (selected: string) => {
      const tagNames = selected.split('.').pop();
      const selectedId = businessTags?.find(
        (tag: BusinessTag) => tag.name === tagNames,
      )?.id;
      if (inputValues?.category?.includes(selectedId)) {
        setInputValues({
          ...inputValues,
          category: inputValues?.category?.filter((i: any) => i !== selectedId),
        });
        return;
      }
      const category = inputValues?.category || [];
      setInputValues({
        ...inputValues,
        category: [...category, selectedId],
      });
    },
    [businessTags, inputValues, setInputValues],
  );

  const selectedCategory = useMemo(() => {
    let res: string[] = [];
    businessTags?.forEach((el) => {
      if (inputValues?.category?.includes(el?.id)) {
        res.push(`business_tags.${el?.name}`);
      }
    });
    return res;
  }, [businessTags, inputValues?.category]);

  const categoryOptions = useMemo(
    () => businessTags?.map((tag) => `business_tags.${tag.name}`),
    [businessTags],
  );

  const getCurrentLink = useCallback(
    (name: string) => {
      return (
        inputValues?.links?.find((link: any) => link?.type === name)?.url || ''
      );
    },
    [inputValues],
  );

  return {
    handleChange,
    createBusiness,
    loading,
    error,
    handleBusinessCategoriesSelected,
    categoryOptions,
    selectedCategory,
    inputValues,
    getCurrentLink,
  };
}

export default useCreateBusiness;
