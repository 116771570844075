// src/features/businesses/userSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Permission } from 'services/@types';
import PermissionService from 'services/permission.api';
export interface PermissionState {
  permissions: Permission[];
  updatedPermissions: string[];
  permission: Permission | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: PermissionState = {
  permissions: [],
  updatedPermissions: [],
  permission: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};


export const createPermission = createAsyncThunk(
  'business/createBusiness',
  async (permission: Permission, { rejectWithValue }) => {
    try {
      const newBusiness = await PermissionService.createPermission(permission);
      return newBusiness;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePermission = createAsyncThunk(
  'business/updateBusiness',
  async ({id, permission}: {id: string; permission: Partial<Permission>}, { rejectWithValue }) => {
    try {
      const updatedBusiness = await PermissionService.updatePermission(id, permission);
      return updatedBusiness;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);


export const deletePermission = createAsyncThunk(
  'business/deleteBusiness',
  async (id: string, { rejectWithValue }) => {
    try {
      await PermissionService.deletePermission(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(createPermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.permission = action.payload;
      })
      .addCase(createPermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deletePermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deletePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updatePermission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.permission = action.payload;
        state.updatedPermissions = [...state.updatedPermissions, action.payload.id]
      })
      .addCase(updatePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userSlice.reducer;
