import { useMemo } from 'react';
import { format } from 'date-fns';
export default function useFormatDate(date: string | Date) {
  return useMemo(() => {
    if (date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm');
    }
    return '';
  }, [date]);
}
