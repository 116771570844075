// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import dialogsSlice from './dialog/dialogsSlice';
import userReducer from './user/userSlice';
import businessTagSliceReducer from './businessTag/businessTagSlice';
import businessSliceReducer from './business/businessSlice';
import permissionSliceReducer from './permission/permissionSlice';
import statsSliceReducer from './stats/statsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    dialogs: dialogsSlice,
    businessTag: businessTagSliceReducer,
    business: businessSliceReducer,
    permission: permissionSliceReducer,
    stats: statsSliceReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
