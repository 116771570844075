// Chakra imports
import { Flex, Link, Stack, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { MainLogo } from 'components/icons/Icons';
import { SidebarContext } from 'contexts/SidebarContext';

// Assets
import { LanguagePicker } from 'components/languagePicker/LanguagePicker';

export default function AuthNavbar(props: {
  logo?: JSX.Element | string;
  logoText?: string;
  secondary?: boolean;
  sidebarWidth?: any;
}) {
  const { logoText, sidebarWidth } = props;
  // Menus

  let logoColor = useColorModeValue('white', 'white');
  // Chakra color mode

  let mainText = '#fff';
  let navbarBg = 'none';
  let navbarShadow = 'initial';
  let navbarPosition = 'absolute' as 'absolute';

  let brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/#/`}
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack
        direction="row"
        spacing="12px"
        alignItems="center"
        justify="center"
      >
        <MainLogo h="26px" w="175px" color={logoColor} />
      </Stack>
      <Text fontSize="sm" mt="3px">
        {logoText}
      </Text>
    </Link>
  );
  if (props.secondary === true) {
    brand = (
      <Link
        minW="175px"
        href={`${process.env.PUBLIC_URL}/#/`}
        target="_blank"
        display="flex"
        lineHeight="100%"
        fontWeight="bold"
        justifyContent="center"
        alignItems="center"
        color={mainText}
      >
        <MainLogo h="26px" w="175px" my="32px" color={logoColor} />
      </Link>
    );
  }

  return (
    <SidebarContext.Provider value={{ sidebarWidth }}>
      <Flex
        position={navbarPosition}
        top="16px"
        left="50%"
        transform="translate(-50%, 0px)"
        background={navbarBg}
        boxShadow={navbarShadow}
        borderRadius="15px"
        px="16px"
        py="22px"
        mx="auto"
        width="1044px"
        maxW="90%"
        alignItems="center"
        zIndex="3"
      >
        <Flex w="100%" justifyContent={{ sm: 'start', lg: 'space-between' }}>
          {brand}
          <LanguagePicker />
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  );
}
