import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../../../services/auth.api';
export const useSignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const signUp = useCallback(
    async (
      firstName: string,
      familyName: string,
      email: string,
      password: string,
      phone: string,
    ) => {
      // Your logic here
      try {
        setLoading(true);
        await AuthService.register({
          name: firstName,
          familyName,
          email,
          password,
          phone,
        });
      } catch (e) {
        const error = e as AxiosError;
        // redirect to sign in page if admin is created
        if (error?.response?.status === 423) {
          navigate('/auth/sign-in');
        }
        setError(error.message);
      }
      setLoading(false);
    },
    [navigate],
  );

  return {
    inputValues,
    setInputValues,
    signUp,
    loading,
    setLoading,
    error,
    setError,
    show,
    setShow,
    
  };
};
