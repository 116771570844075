import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UserWithTokens, isAdminExist } from './@types';
import store from '../redux/store';
import { clearCredentials, setCredentials } from '../redux/auth/authSlice';

class AuthService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const state = store.getState();
      const token = state.auth.tokens?.access;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }
  public async healthCheck(): Promise<void> {
    await this.api.get('/auth/health');
  }

  public async register(user: {
    name: string;
    familyName: string;
    email: string;
    password: string;
    phone: string;
  }): Promise<void> {
    const response: AxiosResponse<UserWithTokens> = await this.api.post(
      '/auth/register',
      user,
    );
    store.dispatch(setCredentials(response.data));
    localStorage.setItem('token', JSON.stringify(response.data));
  }

  public async login(credentials: {
    email: string;
    password: string;
  }): Promise<void> {
    const response: AxiosResponse<UserWithTokens> = await this.api.post(
      '/auth/login',
      credentials,
    );
    store.dispatch(setCredentials(response.data as UserWithTokens));
    localStorage.setItem('token', JSON.stringify(response.data));
  }

  public async logout(): Promise<void> {
    try {
      const state = store.getState();
      const refreshToken = state.auth.tokens?.refresh.token;
      await this.api.post('/auth/logout', { refreshToken });
      store.dispatch(clearCredentials());
      localStorage.removeItem('token');
    } catch (error) {
      console.error(error);
    }
  }

  public async refreshTokens(): Promise<void> {
    const state = store.getState();
    const refreshToken = state.auth.tokens?.refresh;
    const response: AxiosResponse<UserWithTokens> = await this.api.post(
      '/auth/refresh-tokens',
      { refreshToken },
    );
    store.dispatch(setCredentials(response.data as UserWithTokens));
    localStorage.setItem('token', JSON.stringify(response.data));
  }

  public async forgotPassword(email: string): Promise<void> {
    await this.api.post('/auth/forgot-password', { email });
  }

  public async resetPassword(token: string, password: string): Promise<void> {
    await this.api.post(
      '/auth/reset-password',
      { password },
      {
        params: { token },
      },
    );
  }

  public async sendVerificationEmail(): Promise<void> {
    await this.api.post('/auth/send-verification-email');
  }

  public async verifyEmail(token: string): Promise<void> {
    await this.api.post(
      '/auth/verify-email',
      {},
      {
        params: { token },
      },
    );
  }

  public async isAdminExist(): Promise<isAdminExist> {
    const response: AxiosResponse<isAdminExist> = await this.api.get(
      '/auth/is-admin-exist',
    );
    return response.data;
  }
}

const _authService = new AuthService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000',
);
export default _authService;
