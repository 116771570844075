// Chakra imports
import { Flex, Text, useColorModeValue, Badge } from '@chakra-ui/react';
import AuthImageContainer from 'components/authImageContainer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import { Business } from 'services/@types';
import useFormatDate from 'utils/hooks/useFormatDate';

export default function BusinessBadge(props: {
  business: Business;
  [x: string]: any;
}) {
  const { business, ...rest } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessTags } = useSelector((state: RootState) => state.businessTag);

  const filteredBusinessTags = useMemo(() => {
    const filteredBusinessTags: string[] = [];
    business?.category?.forEach((id: string) => {
      const businessTag = businessTags?.find(
        (businessTag) => businessTag?.id === id,
      );
      if (businessTag) {
        filteredBusinessTags.push(t(`business_tags.${businessTag.name}`));
      }
    });
    return filteredBusinessTags.join(', ');
  }, [businessTags, t, business?.category]);

  const imgPath = useMemo(
    () => business?.media?.find((i: any) => i.type === 'profileImage')?.url,
    [business],
  );

  const handleClick = useCallback(() => {
    navigate('/admin/businesses/update_business', {
      state: business,
    });
  }, [navigate, business]);

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const formattedDate = useFormatDate(business?.createdAt);
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      wrap="wrap"
      gap={5}
      {...rest}
    >
      <AuthImageContainer
        path={imgPath}
        name={business?.businessName}
        h="34px"
        w="34px"
        borderRadius="50%"
      />
      <Flex direction="column" align="start" me="auto">
        <Text
          color={textColor}
          fontSize="md"
          me="6px"
          fontWeight="700"
          cursor="pointer"
          onClick={handleClick}
        >
          {business?.businessName}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {formattedDate}
        </Text>
      </Flex>
      {filteredBusinessTags && (
        <Badge
          ms="auto"
          colorScheme={'green'}
          color={'green.500'}
          fontSize="sm"
        >
          {filteredBusinessTags}
        </Badge>
      )}
    </Flex>
  );
}
