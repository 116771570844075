import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import BusinessBadge from 'components/businessBadge';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import PermissionsCard from 'components/permissionsCard';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  closeAlertDialog,
  openAlertDialog,
  openBusinessDialog,
} from '../../../../../redux/dialog/dialogsSlice';
import useDeleteUser from './hooks/useDeleteUser';
// Custom hooks
import useUpdateUser from './hooks/useUpdateUser';

// user roles
const USER_ROLES = [
  { value: 'personal_user', label: 'user_roles.personal_user' },
  { value: 'professional', label: 'user_roles.professional' },
  { value: 'business', label: 'user_roles.business' },
  { value: 'additional_seats', label: 'user_roles.additional_seats' },
  { value: 'event_owner', label: 'user_roles.event_owner' },
];

function UpdateBrilliantUser() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const {
    handleChange,
    updateUser,
    loading,
    error,
    currentBusiness,
    user,
    fields,
  } = useUpdateUser();

  const openBusinessModal = useCallback(() => {
    dispatch(
      openBusinessDialog({
        onClose: () => {},
        onConfirm: () => {},
      }),
    );
  }, [dispatch]);

  // custom hook for deleting user
  const { deleteUser } = useDeleteUser();

  // handle click event for delete button
  const handleClick = (id: string): void => {
    dispatch(
      openAlertDialog({
        content: t('user_table.are_u_sure'),
        title: t('user_table.delete_user'),
        onClose: () => {
          dispatch(closeAlertDialog());
        },
        onConfirm: () => {
          deleteUser(id);
          dispatch(closeAlertDialog());
          navigate(-1);
        },
      }),
    );
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Box
        h="45vh"
        bgGradient="linear(to-b, brand.400, brand.600)"
        position="absolute"
        w="100%"
        borderRadius="20px"
      />

      <Tabs
        variant="unstyled"
        zIndex="0"
        mt={{ base: '60px', md: '165px' }}
        display="flex"
        flexDirection="column"
      >
        <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
          <TabPanel
            w={{ sm: '330px', md: '700px', lg: '850px' }}
            p="0px"
            mx="auto"
          >
            <Flex flexDirection="column" gap={10}>
              <Card p="30px">
                <Text
                  color={textColor}
                  fontSize="2xl"
                  fontWeight="700"
                  mb="20px"
                >
                  {t('create_update_user.update_user')}
                </Text>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <SimpleGrid gap="20px">
                      <FormControl>
                        {fields.map((field, key) => (
                          <React.Fragment key={key}>
                            <FormLabel
                              fontWeight="600"
                              m="10px 10px 0"
                              color={textColor}
                              fontSize="sm"
                            >
                              {t(`create_update_user.${field.name}`)}
                            </FormLabel>
                            <InputField
                              mb="0px"
                              id="first"
                              placeholder={t(
                                `create_update_user.placeholder.${field.name}`,
                              )}
                              name={field.name}
                              defaultValue={field.value}
                              onChange={handleChange}
                            />
                          </React.Fragment>
                        ))}
                        <FormLabel
                          fontWeight="600"
                          m="10px 10px 0"
                          color={textColor}
                          fontSize="sm"
                        >
                          {t('create_update_user.choose_user_role')}
                        </FormLabel>
                        <Select
                          fontSize="sm"
                          id="color"
                          variant="main"
                          h="44px"
                          maxH="44px"
                          fontWeight="400"
                          me="20px"
                          mt="10px"
                          defaultValue="dark_grey"
                          onChange={handleChange}
                          name="roles"
                          value={user?.roles[0] || USER_ROLES[0].value}
                        >
                          {USER_ROLES.map((role, key) => (
                            <option key={key} value={role.value}>
                              {t(role.label)}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </SimpleGrid>
                  </Stack>
                  <Text color="red.500" fontSize="sm" textAlign="center">
                    {error}
                  </Text>
                </Flex>
              </Card>
              <Card>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  gap="3"
                >
                  <FormLabel fontSize="2xl" fontWeight="700" color={textColor}>
                    {t('create_update_user.business')}
                  </FormLabel>
                  <Button
                    colorScheme="grey"
                    variant="outline"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    ms="auto"
                    onClick={() => openBusinessModal()}
                    isLoading={loading}
                  >
                    {t('create_update_user.change_business')}
                  </Button>
                </Flex>

                <Flex gap={5} m="10px" wrap="wrap">
                  {currentBusiness && (
                    <BusinessBadge business={currentBusiness} />
                  )}
                </Flex>
              </Card>
              <PermissionsCard />
              <Card>
                <Flex wrap="wrap" justifyContent="space-between" gap={5}>
                  <Flex>
                    <Button
                      variant="outline"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      isLoading={loading}
                      colorScheme="red"
                      onClick={() => handleClick(user.id)}
                    >
                      {t('create_update_user.delete_user')}
                    </Button>
                  </Flex>
                  <Flex gap={5}>
                    <Button
                      colorScheme="grey"
                      variant="outline"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      onClick={() => navigate(-1)}
                      isLoading={loading}
                    >
                      {t('create_update_user.cancel')}
                    </Button>
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      onClick={() => updateUser()}
                      isLoading={loading}
                    >
                      {t('create_update_user.update_user')}
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default UpdateBrilliantUser;
