import { Button, Flex, Icon, Select, Stack, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import { useTranslation } from 'react-i18next';
import { NonActiveUserData } from 'services/@types';
import useNonActiveUsers from './hooks/useNonActiveUsers';
import NonActiveUser from './nonActiveUser';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
const DAYS_AGO: number[] = [7, 30, 90];
const DEFAULT_PAGE_SIZES: number[] = [6, 7, 8, 9, 10];
export default function NonActiveUsers() {
  const { t } = useTranslation();
  const {
    nonActiveUsers,
    pages,
    handleChangeParams,
    pageSize,
    page,
    totalPages,
  } = useNonActiveUsers();
  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card gap={2}>
        <Flex justifyContent="space-between" alignItems="center" gap={2}>
          <Text fontSize="xl" fontWeight="bold">
            {t('non_active_users.list_of_non_active_users')}
          </Text>
          <Select
            maxW="150px"
            textAlign="center"
            name="daysAgo"
            onChange={(e) =>
              handleChangeParams(Number(e.target.value), e.target.name)
            }
          >
            {DAYS_AGO.map((day) => (
              <option key={day} value={day}>
                {`${day} ${t('non_active_users.days_ago')}`}
              </option>
            ))}
          </Select>
        </Flex>
        <HSeparator />
        <Flex direction="column" gap={3}>
          {nonActiveUsers.length === 0 && (
            <Text textAlign="center" color="rgba(135, 140, 189, 0.3)">
              {t('non_active_users.no_non_active_users')}
            </Text>
          )}
          {nonActiveUsers.map((user: NonActiveUserData) => (
            <NonActiveUser key={user.userId} user={user} />
          ))}
        </Flex>
        {nonActiveUsers.length !== 0 && (
          <Flex alignItems="center" justify="space-between">
            <Select
              fontSize="sm"
              textAlign="center"
              variant="main"
              value={pageSize}
              name="pageSize"
              onChange={(e) =>
                handleChangeParams(Number(e.target.value), e.target.name)
              }
              maxW="100px"
            >
              {DEFAULT_PAGE_SIZES.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
            <Flex>
              <Stack
                direction="row"
                alignSelf="flex-end"
                spacing="4px"
                ms="auto"
              >
                <Button
                  onClick={() => handleChangeParams(page - 1, 'page')}
                  disabled={page === 1}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  variant="no-effects"
                  borderRadius="50%"
                  bg="transparent"
                  border="1px solid"
                  borderColor={'blackAlpha.100'}
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon as={MdChevronLeft} w="16px" h="16px" color="gray" />
                </Button>
                {pages.map((pageNumber, index) => {
                  return (
                    <Button
                      variant="no-effects"
                      transition="all .5s ease"
                      onClick={() => handleChangeParams(pageNumber, 'page')}
                      w="40px"
                      h="40px"
                      borderRadius="50%"
                      bg={pageNumber === page ? 'green' : 'transparent'}
                      border={
                        pageNumber === page ? 'none' : '1px solid lightgray'
                      }
                      _hover={
                        pageNumber === page
                          ? {
                              opacity: '0.7',
                            }
                          : {
                              bg: 'whiteAlpha.100',
                            }
                      }
                      key={index}
                    >
                      <Text
                        fontSize="sm"
                        color={pageNumber === page ? '#fff' : 'red'}
                      >
                        {pageNumber}
                      </Text>
                    </Button>
                  );
                })}
                <Button
                  onClick={() => handleChangeParams(page + 1, 'page')}
                  disabled={page === totalPages}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  variant="no-effects"
                  borderRadius="50%"
                  bg="transparent"
                  border="1px solid"
                  borderColor={'blackAlpha.100'}
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon as={MdChevronRight} w="16px" h="16px" color="gray" />
                </Button>
              </Stack>
            </Flex>
          </Flex>
        )}
      </Card>
    </Flex>
  );
}
